import React, { createContext, useState, ReactNode, useContext } from 'react';
import { uploadFile } from './fileUpload';
import { AuthContext } from '../../auth/authContext';

interface FilesContextInterface {
    files: ExtendedFile[];
    addFiles: (newValue: File[]) => void;
    rmFile: (file: ExtendedFile) => void;
    uploadFiles: () => void;
    uploading: boolean;
    tableType: TableType;
    setTableType: (tableType: TableType) => void;
};

export interface ExtendedFile extends File {
    uploading: boolean;
    error: string;
}

export type TableType = 'outscraper' | 'texau';

export const FilesContext = createContext<FilesContextInterface>({
    files: [], addFiles: () => { }, rmFile: () => { }, uploadFiles: () => { }, uploading: false
    , tableType: 'outscraper', setTableType: () => { }
});

// Create a provider component
export const FilesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [value, setValue] = useState<ExtendedFile[]>([]);
    const [uploading, setUploading] = useState(false);
    const [tableType, setTableType] = useState<TableType>('outscraper');
    const auth = useContext(AuthContext);

    const addFiles = (newFiles: File[]) => {
        setValue((prevFiles) => {
            // Adds extended file properties to the files
            const newFilesExtended = newFiles.map(file => {
                (file as ExtendedFile).uploading = false;
                (file as ExtendedFile).error = '';
                return file as ExtendedFile;
            });

            // Creates a combined array
            const combinedFiles = prevFiles.concat(newFilesExtended);

            // Deduplicates based on the file path (name)
            const uniqueFiles = combinedFiles.filter((file, index, self) =>
                index === self.findIndex((f) => f.name === file.name)
            );

            return uniqueFiles;
        });
    };

    const rmFile = (file: ExtendedFile) => {
        setValue((prevFiles) => {
            return prevFiles.filter((f) => f.name !== file.name);
        });
    };

    const uploadFiles = async () => {
        setUploading(true);
        await Promise.all(value.map(async file => {
            try {
                console.log('Uploading file: ' + file);
                file.uploading = true;
                await uploadFile(file, tableType, auth.password);
                rmFile(file);
            } catch (e: any) {
                file.error = e.message;
                file.uploading = false;
            };
        }))
        setUploading(false);
    };

    return <FilesContext.Provider value={{ files: value, addFiles, rmFile, uploadFiles, uploading, tableType, setTableType }}>
        {children}
    </FilesContext.Provider>;
};

