import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBRWEvzwL6u0xbMsVUrSULm6XdpbN62Lcw",
  authDomain: "rb-tools-e8bf7.firebaseapp.com",
  projectId: "rb-tools-e8bf7",
  storageBucket: "rb-tools-e8bf7.firebasestorage.app",
  messagingSenderId: "424864562599",
  appId: "1:424864562599:web:9bef09995d9896b1354a82"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);