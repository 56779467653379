import React, { useContext } from 'react';
import { FilesContext } from './fileContext';
import Chip from '@mui/material/Chip';
import { CircularProgress, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export const FilesDisplay: React.FC = () => {
    const context = useContext(FilesContext);
    const files = context.files;
    const maxWidth = '800px';

    return <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
        {
            files.length > 0
                ? React.Children.toArray(context.files.map(file => {
                    return <>
                    <Chip
                        sx={{ minWidth: '350px', maxWidth: maxWidth }}
                        label={file.name}
                        onDelete={() => context.rmFile(file)}
                        // disabled={file.uploading}
                        color={file.uploading ? 'primary' : 'default'}
                        deleteIcon={file.uploading ? <CircularIcon /> : <DeleteIcon />}
                    />
                    {
                        file.error && <Typography variant='caption' style={{ color: 'red', maxWidth: maxWidth }}>{file.error}</Typography>
                    }
                    </>;
                }))
                : <Typography style={{ marginTop: '7.5px' }}>No files selected.</Typography>
        }
    </div>
};

const CircularIcon = () => {
    return <CircularProgress size='12.5px' color='inherit' style={{ marginLeft: '0px', marginRight: '10px' }} />
};
