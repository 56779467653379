import React, { createContext, useState, ReactNode, useContext, useEffect } from 'react';
import { z } from 'zod';
import { AuthContext } from '../auth/authContext';
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from '../../firebase/firebase';

const url = "https://us-central1-etl-directories1.cloudfunctions.net/cf_mysql_etl_trigger" // http://localhost:8080

export const etlHistorySchema = z.object({
    running: z.boolean()
    , version: z.string()
    , stored_procedure: z.string()
    , status: z.enum(['Started', 'Success', "Error"])
    , unix_secs: z.number().int()
    , error_message: z.string().optional()
    , etl_instance_millis: z.number().int().optional()
    , origin_rows: z.number().int().optional()
    , logs_rows: z.number().int().optional()
    , reviews_rows: z.number().int().optional()
    , truncate_instance_millis: z.number().int().optional()
    , mode: z.enum(['etl', 'truncate'])
    , id: z.string()
});

export type EtlHistoryDoc = z.infer<typeof etlHistorySchema>;

interface EtlContextInterface {
    status: "Rest" | "Running";
    history: EtlHistoryDoc[];
    updateHistory: () => Promise<void>;
    runEtl: () => Promise<void>;
    runTruncate: () => Promise<void>;
};

export const EtlContext = createContext<EtlContextInterface>({
    status: 'Rest', history: [], updateHistory: async () => { }, runEtl: async () => { }, runTruncate: async () => { }
});

// Create a provider component
export const EtlProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [history, setHistory] = useState<EtlHistoryDoc[]>([]);
    const [status, setStatus] = useState<"Rest" | "Running">('Rest');
    const auth = useContext(AuthContext);

    const updateHistory = async () => {
        const triggersRef = collection(db, "etl-trigger");
        const q = query(triggersRef, orderBy('unix_secs', 'desc'));
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id } as EtlHistoryDoc;
        });
        setHistory(data);
    };

    const runEtl = async () => {
        setStatus("Running");

        const formData = new FormData();
        formData.append('password', auth.password);
        formData.append('version', 'Google Maps v5');
        formData.append('mode', 'etl');

        try {
            await fetch(url, { method: 'POST', body: formData, });
        } catch (e) {
            console.log(e);
        };

        await updateHistory();
        setStatus("Rest");
    };

    const runTruncate = async () => {
        setStatus("Running");

        const formData = new FormData();
        formData.append('password', auth.password);
        formData.append('version', 'Google Maps v5');
        formData.append('mode', 'truncate');
        try {
            await fetch(url, { method: 'POST', body: formData, });
        } catch (e) {
            console.log(e);
        };

        await updateHistory();
        setStatus("Rest");
    };

    useEffect(() => {
        updateHistory();
    }, []);

    return <EtlContext.Provider value={{ status, history, updateHistory, runEtl, runTruncate }}>
        {children}
    </EtlContext.Provider>;
};

// TODO runEtl

